
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Financement";
export const lead = "Un pays sur trois ne respecte aucun des grands critères en matière de financement énoncés par le Cadre d’action Éducation 2030 à l’intention des pouvoirs publics.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <p>{`Les cibles relatives à l’éducation, ambitieuses, ne seront pas réalisées à temps sans ressources supplémentaires, notamment dans les pays qui accusent le plus grand retard.`}</p>
    <p>{`Le rapport GEM calcule que pour parvenir à l’objectif 2030 pour l’éducation de base universelle, c’est-à-dire l’enseignement préprimaire, primaire et secondaire, les pays à revenu faible et à revenu intermédiaire de la tranche inférieure doivent dépenser 461 milliards de dollars É.-U. par an. Il est peu probable que de nombreux pays augmentent leurs budgets de manière adéquate en raison de la faiblesse de leurs recettes fiscales, ce qui se traduit par un déficit de financement annuel estimé à 97 milliards de dollars É.-U. (21 % du coût total) pour la réalisation des objectifs nationaux entre 2023 et 2030. Le déficit moyen est de 26 milliards de dollars É.-U. (50 % du total) dans les pays à faible revenu et de 71 milliards de dollars É.-U. (17 %) dans les pays à revenu intermédiaire de la tranche inférieure, ce qui équivaut à 2,3 % du PIB pendant cette période.`}</p>
    <IndicatorReference id="4.5.3" mdxType="IndicatorReference">
      <p>{`Les pays qui dépensent le même montant en termes de revenu par habitant peuvent obtenir des résultats très différents en matière d'éducation, selon qu'ils dépensent de manière efficace, efficiente et équitable. Par exemple, sur les 78 pays à revenu faible ou intermédiaire pour lesquels des informations sont disponibles, seuls 17 - soit un sur cinq - ont mis l'accent sur l'équité dans leurs politiques de financement. Il s'agit principalement de pays à revenu moyen supérieur et de pays d'Amérique latine.`}</p>
    </IndicatorReference>
    <p>{`Les trois sources principales de dépenses d'éducation sont les gouvernements, les ménages et les donateurs. Cette page se concentrera sur les deux premiers. Une page distincte est consacrée à `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/fr/articles/finance-aid"
      }}>{`l’aide à l’éducation`}</a>{`.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`En 2022, sur 5 000 milliards de dollars É.-U. dépensés dans l’éducation dans le monde entier, seuls 0,45 % correspondent à des pays à faible revenu, contre 64 % pour les pays à revenu élevé. Dans les deux groupes, pourtant, le nombre d’enfants en âge d’être scolarisés est globalement le même.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
  
Dans le pays médian, <HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">les ménages</HighlightByPalette>  prennent en charge 25 % du coût des études. Les ménages participent en fait bien plus aux frais liés aux études dans les pays à revenu intermédiaire de la tranche inférieure (44 %) que dans les pays à revenu élevé (20 %).
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
  
Bien que les <HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">donateurs</HighlightByPalette> contribuent à hauteur de 12 % aux dépensés dans l’éducation dans les pays à faible revenu…
  
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">  
  
… les contributions <HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">des donateurs</HighlightByPalette> représentent seulement 0,3 % des dépenses à l’échelle mondiale, un effort à peine visible en termes absolus.
  
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "dépense-publique"
    }}>{`Dépense publique`}</h2>
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`Le `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656_fre"
        }}>{`Cadre d’action Éducation 2030`}</a>{` a établi deux grands critères de financement à l’intention des pouvoirs publics. Il leur recommande:`}</p>
      <ul>
        <li parentName="ul">{`de consacrer au moins 4 à 6 % du PIB à l’éducation et/ou`}</li>
        <li parentName="ul">{`de consacrer au moins 15 à 20 % des dépenses publiques à l’éducation.`}</li>
      </ul>
      <p>{`Ces niveaux ont été globalement observés dans le monde entier. Les dépenses publiques médianes représentent 4,0 % du PIB et 12,6 % des dépenses publiques totales. Les États des pays les plus pauvres avec peu de capacités à générer des ressources nationales mais une grande population d’enfants ont tendance à moins dépenser en matière d’éducation par rapport au PIB, mais plus par rapport au total des dépenses publiques.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`En Afrique subsaharienne, les dépenses publiques en matière d’éducation se situent à 15,5 % du total des dépenses publiques, mais à 3,5 % du PIB. Inversement, les pays d’Europe et d’Amérique du Nord remplissent l’objectif intermédiaire par rapport au PIB (4,8 %), mais affectent une faible part du total des dépenses publiques à l’éducation (10,9 %).`}</p>
      <p>{`Mais, dans l’ensemble, environ un pays sur trois disposant de données récentes ne remplit pas l’un ou l’autre de ces objectifs intermédiaires. Affichez l’historique de ces données pour observer comment ces dépenses fluctuent selon les années.`}</p>
    </IndicatorReference>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <p>{`Les principales recettes publiques proviennent de la fiscalité, même dans les pays à faible revenu qui tirent une grande part de leurs revenus de subventions (c’est-à-dire sous forme d’aide). Dans l’ensemble, les recettes fiscales moyennes par rapport au PIB s’établissaient à 14 % dans les pays à faible revenu, à 18 % dans les pays à revenu intermédiaire de la tranche inférieure, à 22 % dans les pays à revenu intermédiaire de la tranche supérieure et à 33 % dans les pays à revenu élevé. Même si tous les pays ont donné à l’éducation la même priorité dans leur budget, par exemple, les pays qui lèvent plus d’impôts et d’autres ressources nationales dépenseront davantage dans l’éducation.`}</p>
    <p>{`Cela étant, la variation importante du montant dépensé dans le domaine éducatif entre pays affichant des caractéristiques par ailleurs similaires indique que ces relations ne sont en rien prédéterminées. Certains pays font le choix de dépenser plus dans l’éducation pour atteindre leurs objectifs. Or il nous faudrait insister sur ceux qui ne le font pas.`}</p>
    <h2 {...{
      "id": "dépenses-des-ménages"
    }}>{`Dépenses des ménages`}</h2>
    <p>{`Les ménages consacrent un montant important de leurs revenus à l’éducation de leurs enfants et c’est d’autant plus le cas dans les pays les plus pauvres.`}</p>
    <p>{`Ces dépenses peuvent prendre la forme des frais de scolarité et autres, mais aussi de biens et services éducatifs, dont certains sont obligatoires (par exemple, les manuels scolaires) et d’autres facultatifs (comme les cours particuliers).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Lorsque ces données sont standardisées d’un pays à un autre, la contribution des ménages aux dépenses totale en matière d’éducation ressort nettement. Voilà qui peut apporter une nouvelle compréhension des efforts des différents pays en matière d’éducation.`}</p>
      <p>{`Dans les pays à faible revenu, les ménages consacrent moins de 1 % de leur budget à l'éducation, en raison des difficultés économiques. En revanche, les ménages des pays à revenu intermédiaire inférieur consacrent une médiane de 2,9 % et une moyenne non pondérée de 3,6 % de leurs dépenses à l'éducation, la répartition de leurs dépenses étant la plus large de tous les groupes de revenus. Le niveau élevé des dépenses dans les pays à revenu intermédiaire de la tranche inférieure suggère que les ménages subissent des pressions pour compléter le financement public inadéquat. Dans les pays à revenu intermédiaire supérieur, la part médiane du budget des ménages consacrée à l'éducation tombe à 1,5 %, avec une moyenne non pondérée de 2,3 %. Enfin, dans les pays à revenu élevé, les ménages consacrent à l'éducation une part médiane de 1,3 % ou une moyenne non pondérée de 1,7 %.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,BGD,FRA" mdxType="ScrollySection">
          <p>{`Par exemple, en 2019, `}<strong parentName="p">{`le Bangladesh`}</strong>{` affichait l’un des plus faibles niveaux de `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`dépenses publiques`}</HighlightByIndex>{` en pourcentage du PIB. Néanmoins, après prise en compte des `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`contributions des ménages`}</HighlightByIndex>{`, les dépenses nationales totales du Bangladesh dans l’éducation en pourcentage du PIB étaient très similaires à celles de `}<strong parentName="p">{`la France`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,IND,BEL" mdxType="ScrollySection">
          <p>{`L’État `}<strong parentName="p">{`de l'Inde`}</strong>{` a consacré environ 2,5 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`points de pourcentage du PIB`}</HighlightByIndex>{` de moins à l’éducation que `}<strong parentName="p">{`la Belgique`}</strong>{` (barre bleue). Cependant, l'Inde a dépensé globalement plus que la Belgique, car ses `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`ménages`}</HighlightByIndex>{` ont dépensé plus de 2.6 points de pourcentage du PIB en matière d’éducation (barre rouge).`}</p>
        </ScrollySection>
        <ScrollySection id="relative,NGA,LBN,HTI" mdxType="ScrollySection">
          <p>{`La `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`part des ménages`}</HighlightByIndex>{` dans les dépenses totales d’éducation grimpe à plus de 70 % dans certains pays, dont `}<strong parentName="p">{`le Nigeria`}</strong>{`, `}<strong parentName="p">{`Haïti`}</strong>{` et `}<strong parentName="p">{`le Liban`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`L’équipe du Rapport mondial de suivi sur l'éducation soutient depuis longtemps que comprendre les dépenses mondiales dans l’éducation exige d’examiner conjointement ses trois sources principales de financement : le financement national public et privé - discutés dans cette page - et les donateurs externes, discutés dans la page sur `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/fr/articles/finance-aid"
      }}>{`l'aide à l'éducation`}</a>{`. Il est donc nécessaire de disposer de données à jour et cohérentes des pays à revenu faible et à revenu intermédiaire sur le montant des dépenses dans l’éducation et leurs sources.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2024efw"
          }}>{`Education Finance Watch 2024`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/financing-for-equity"
          }}>{`Unlocking Financing for Equity in Education`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://education-profiles.org/"
          }}>{`Profiles Enhancing Education Reviews (PEER)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/3141"
          }}>{`COVID-19 is a Serious Threat to Aid to Education Recovery`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;