import { t } from "@lingui/macro";
import { useReadScrolly } from "components/scrolly";
import { curveLinear } from "d3-shape";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_aid_fig_AID_EDU_SSA";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  getCountryName,
  mkGemEntityDecoder,
  RegionId,
  regionIdIso,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
  useRegionEntities,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_finance_aid_fig_AID_EDU_SSA";

const DEFAULT_ENTITIES = ["USA", "FRA", "World Bank (IDA)"];

const yUnitLabel = t(
  "fig.finance.aid.AID_EDU_SSA.yUnitLabel"
)`Constant 2022 US$ millions`;

export const Data = mkGemEntityDecoder(["ind_id", "year", "value"], {});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedEntities] = useMultiSelectState("entities");
  const { activeSection } = useReadScrolly();

  const scrollDonors = React.useMemo(() => {
    return pipe(
      activeSection,
      O.map((x) => x.split(",")),
      O.fold(
        () => [],
        (ids) => {
          return ids.map((id) =>
            getCountryName(env.countries, countryIdIso.wrap(id))
          );
        }
      )
    );
  }, [env.countries, activeSection]);

  const entityData = useNamedEntities(data);
  const countryData = useCountryEntities(entityData);
  const regionData = useRegionEntities(entityData);
  const contryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );
  const regionControlItems = useFigureControlItems(
    regionData,
    (x) => regionIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "entities",
      {
        type: "MultiSelect",
        label: i18n._(t`Entities`),
        selected: O.some(DEFAULT_ENTITIES),
      },
      contryControlItems.concat(regionControlItems)
    );
  }, [i18n, actions, env.countries, contryControlItems, regionControlItems]);

  const chartData = React.useMemo(() => {
    const hasCountry = (country: CountryId) =>
      pipe(
        selectedEntities,
        O.fold(
          () => false,
          (xs) => xs.includes(countryIdIso.unwrap(country))
        )
      );
    const chartCountryData = countryData.filter(({ id }) => hasCountry(id));

    const hasRegion = (region: RegionId) =>
      pipe(
        selectedEntities,
        O.fold(
          () => false,
          (xs) => xs.includes(regionIdIso.unwrap(region))
        )
      );
    const chartRegionData = regionData.filter(({ id }) => hasRegion(id));

    return [...chartCountryData, ...chartRegionData];
  }, [countryData, regionData, selectedEntities]);

  const selectedCountryLabels = React.useMemo(() => {
    return pipe(
      selectedEntities,
      O.map((xs) =>
        xs.map((x) => getCountryName(env.countries, countryIdIso.wrap(x)))
      )
    );
  }, [env.countries, selectedEntities]);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    selectedCountryLabels
  );

  return (
    <>
      <LineChart
        tLabel={(s) => s}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        x="year"
        timeParse="%Y"
        timeFormat="%Y"
        numberFormat=".0f"
        category="datum.entity_name"
        colorLegend={false}
        colorScale={colorScale}
        connectLines
        values={chartData}
        markStyle={(x) => (x.entity_type === "country" ? "normal" : "muted")}
        showTooltip
        curve={curveLinear}
        yAxisLabel={i18n._(yUnitLabel)}
        isFaded={(x) =>
          scrollDonors.length > 0 && !scrollDonors.includes(x.entity_name)
        }
      />
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_AID_EDU_SSA.json"),
  csv: require("../data/data_finance_aid_fig_AID_EDU_SSA.zip"),
  xlsx: require("../data/data_finance_aid_fig_AID_EDU_SSA.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
});
