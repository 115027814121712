
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Quality";
export const lead = "Globally, most teachers are trained but in sub-Saharan Africa, the percentage of trained teachers fell gradually from 85% in 2000 to 64% in 2022.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const QualityFramework = makeShortcode("QualityFramework");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_QUALITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Good quality education cannot be equated with, or reduced to, learning outcomes. Too many children are denied sufficiently trained teachers, good school infrastructure or a safe, non-violent learning environment.`}</p>
    </blockquote>
    <p>{`It is hard to monitor good quality education because each country understands and defines it in different ways. Apart from learning outcomes, there are not many indicators for measuring quality in the global education goal, SDG 4.`}</p>
    <h2 {...{
      "id": "teachers"
    }}>{`Teachers`}</h2>
    <IndicatorReference id="4.c.2" mdxType="IndicatorReference">
      <p>{`Teachers are the cornerstone of quality in education. However, after 2000, in the push to achieve universal primary education, many more students entered the classroom and the recruitment of teachers did not keep pace. There are over-crowded classrooms as a result, and the quality of education is suffering.`}</p>
      <QualityFramework highlighted="teachers" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
      <p>{`The graphs below look at the ratio of pupils to teachers in school. In some sub-Saharan African countries, while the ratio has improved since 2000, the pupil per teacher ratio is still worse than in the 1980s.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PTR" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`sub-Saharan Africa`}</HighlightByIndex>{`, after the push to make primary school free between 1990 and 2000, children entered classrooms faster than countries could recruit new teachers. Pupil-teacher-ratios increased often fairly dramatically as a result.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`In `}<HighlightByIndex index="6" mdxType="HighlightByIndex">{`Europe and Northen America`}</HighlightByIndex>{`, meanwhile, the ratio has been constant at about 14 students per teacher since 2005.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Teaching is a challenging task and teachers who are not well prepared enter the classroom with a serious deficit. There are two main ways that countries measure how well prepared they are. Teachers are assessed by:`}</p>
    <ul>
      <li parentName="ul">{`Firstly, whether they are `}<strong parentName="li">{`qualified`}</strong>{`, which indicates whether they have the expected academic credentials according to national standards or not.`}</li>
      <li parentName="ul">{`Secondly, whether they are `}<strong parentName="li">{`trained`}</strong>{`, which, regardless of their qualification, assesses if they received appropriate teacher training or not.`}</li>
    </ul>
    <IndicatorReference id="4.c.1" mdxType="IndicatorReference">
      <p>{`Being trained means that teachers have received training in `}<Tooltip title="Pedagogical knowledge" content="Knowledge on approaches, methods and techniques of teaching." mdxType="Tooltip">{`pedagogical knowledge`}</Tooltip>{` and `}<Tooltip title="Professional knowledge" content="Knowledge on, for example, the legal frameworks governing the teaching profession." mdxType="Tooltip">{`professional knowledge`}</Tooltip>{`. Some programmes may also cover `}<Tooltip title="Content knowledge" content="Knowledge of the curriculum, subject matter and use of relevant materials." mdxType="Tooltip">{`content knowledge`}</Tooltip>{`. But the type of training required differs by country and relevant information is scarce. Because of these differences, despite being a crucial element of a quality education, the indicator on trained teachers `}{`[4.c.1]`}{` is one of the least comparable in the SDG 4 monitoring framework.`}</p>
      <p>{`Globally, the majority of teachers are qualified, trained, or both. For instance, in `}<HighlightByIndex index="3" mdxType="HighlightByIndex">{`Latin America and the Caribbean`}</HighlightByIndex>{`, 80% of teachers are trained.`}</p>
      <p>{`By contrast, in `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`sub-Saharan Africa`}</HighlightByIndex>{`, the percentage of trained teachers fell from 85% in 2000 to 64% in 2022.`}</p>
      <Figure id="TRAINED" mdxType="Figure" />
      <p>{`There is no easy fix for countries that lack qualified and trained teachers. Many do not have enough eligible teachers to meet the demand given the low number of tertiary graduates overall. This goes to show how inter-connected problems within an education system can become: problems in one level, such as low secondary school completion, can easily result in repercussions elsewhere, such as limited trained teachers for students later on.`}</p>
    </IndicatorReference>
    <h2 {...{
      "id": "infrastructure"
    }}>{`Infrastructure`}</h2>
    <IndicatorReference id="4.a.1e" mdxType="IndicatorReference">
      <p>{`An appropriate learning environment must have adequate `}<strong parentName="p">{`water`}</strong>{` and sanitation facilities, especially for girls. Yet, basic water was available in only 53% of primary schools in sub-Saharan Africa, 77% in Eastern and South-Eastern Asia, and 86% in Central and Southern Asia.`}</p>
      <QualityFramework highlighted="material" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1f" mdxType="IndicatorReference">
      <p>{`In particular, `}<strong parentName="p">{`sanitation`}</strong>{` facilities that lack privacy may not be considered safe, while facilities where menstrual hygiene cannot be practiced can prevent girls from attending school. Having single-sex sanitation facilities is therefore a crucial policy move to prevent widespread late enrolment at primary school and female drop out from school over time.`}</p>
      <p>{`The graph below shows that fewer than half of primary schools have single-sex sanitation facilities not only in sub-Saharan African countries but also in Asian countries such as Afghanistan, the Lao PDR and Nepal. In Indonesia, only 43% of primary, 56% of lower secondary and 65% of upper secondary schools had access to single-sex sanitation facilities.`}</p>
      <Figure id="SANITATION" mdxType="Figure" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1a" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Electricity`}</strong>{` is a basic need for a quality learning environment and yet many countries are without it. In low income countries, 73% of primary and 58% of upper secondary schools do not have electricity.`}</p>
      <p>{`As the graph below shows, fewer than half of primary schools have electricity in Nicaragua and Pakistan. In some sub-Saharan African countries, access to electricity is extremely rare in schools: 12% of schools have electricity in Madagascar and just 4% in Chad. Without electricity, light may be inadequate, and teachers cannot use computers for administration or training. A lack of electricity means there will be no ventilation, cooling or heating, which may render classrooms unsuitable for teaching and learning.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1b" mdxType="IndicatorReference">
      <p>{`A lack of electricity also means that using technology and the `}<strong parentName="p">{`internet`}</strong>{` is impossible. However, electricity is not the only factor slowing down the spread of the internet. In middle income countries, 37% of primary schools and 22% of upper secondary schools had electricity but no internet. Even if schools are connected to a power source, power surges and brownouts are common. Internet connectivity depends on the national telecommunications infrastructure, geography and schools’ ability to pay for the service.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="ELECTRIC" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="LKA" mdxType="ScrollySection">
          <p>{`In `}<strong parentName="p">{`Sri Lanka`}</strong>{`, while all primary schools have electricity, only 41% have access to the internet. The same is true for 34% of schools in Turkmenistan and 32% in Jordan.`}</p>
        </ScrollySection>
        <ScrollySection id="ECU,URY" mdxType="ScrollySection">
          <p>{`Many Latin American countries have schools with no electricity and no internet. Only around 43% of schools in `}<strong parentName="p">{`Ecuador`}</strong>{` and 63% in Argentina can say they have access to both. One exception is `}<strong parentName="p">{`Uruguay`}</strong>{`, where 100% of primary schools have access to both.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "violence"
    }}>{`Violence`}</h2>
    <p>{`School-related violent acts or threats can be psychological, physical or sexual and occur on school premises but also on the way to school, at home or in cyberspace. They often come about because of unequal power dynamics and are often the result of negative gender norms and stereotypes.`}</p>
    <p>{`While attention usually focuses on extreme events, such as shootings, it is the more common and often unnoticed forms of violence such as bullying that have the largest negative impact on children and adolescents' education. Data on these issues remains insufficient because the questions that are asked when collecting data involve different definitions of violent behaviour, different time scales for reporting, incomparable response options, privacy arrangements or ethical protocols. This is in addition to under-reporting also taking place for forms of violence such as homophobic bullying, which involve taboos.`}</p>
    <QualityFramework highlighted="school" description="Learn more about the framework for education quality" mdxType="QualityFramework" />
    <IndicatorReference id="4.a.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Bullying`}</strong>{` is the most widely documented form of violence in schools. It can include physical violence, verbal abuse and the intent to cause psychological harm through humiliation or exclusion. The figure below shows that at least one in three adolescents, frequently more boys than girls, are victims of bullying in most countries.`}</p>
      <Figure id="BULLY_FIGHT" mdxType="Figure" />
    </IndicatorReference>
    <p><strong parentName="p">{`Physical violence`}</strong>{` includes being involved in physical fights at school, carrying or being threatened by a weapon such as a gun, knife or club on school property, being a gang member or associate, or suffering physical violence in the hands of a staff member.`}</p>
    <p>{`At least 10% of 15-year-old boys in 24 countries reported having been involved in physical fights 3 times or more in 2022, and the prevalence reached over 30% in Armenia.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Internationally comparable data on teacher indicators is surprisingly scarce at the same time. Relatively few countries report even a basic headcount of teachers, and that does not take teaching hours, teachers in administrative positions and other complexities into account. A typology of standards for trained teachers is needed so that we can properly see the gaps and elevate this issue to the global level.`}</p>
    <p>{`The provision of ‘child, disability and gender sensitive’ education facilities and ‘safe, non-violent, inclusive and effective learning environments for all’ is a challenge in many countries as this article shows. Meanwhile, measuring progress on the issue is hard because the relevant global indicator is not a single measure, but a set of several dimensions.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.oecd-ilibrary.org/education/education-at-a-glance_19991487"
          }}>{`Education at a glance – OECD`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theirworld.org/news/safe-schools-report-reveals-crises-challenges-action-needed-to-educate-children"
          }}>{`Safe Schools Report – Their World`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232107"
          }}>{`School-related gender-based violence is preventing the achievement of quality education for all`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;