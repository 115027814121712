
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Qualité";
export const lead = "L’éducation de bonne qualité ne peut être assimilée ni réduite aux résultats d’apprentissage. Trop nombreux sont les enfants privés d’enseignants suffisamment formés, d’infrastructures scolaires satisfaisantes ou d’un environnement d’apprentissage sûr et non violent.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const QualityFramework = makeShortcode("QualityFramework");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const Tooltip = makeShortcode("Tooltip");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_QUALITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`L’éducation de bonne qualité ne peut être assimilée ni réduite aux résultats d’apprentissage. Trop nombreux sont les enfants privés d’enseignants suffisamment formés, d’infrastructures scolaires satisfaisantes ou d’un environnement d’apprentissage sûr et non violent.`}</p>
    </blockquote>
    <p>{`Il est difficile d’évaluer l’éducation de bonne qualité car chaque pays en a sa propre interprétation et sa propre définition. Hormis les résultats d’apprentissage, il n’existe pas beaucoup d’indicateurs mesurant la qualité au regard de l’objectif mondial en matière d’éducation, l’ODD 4.`}</p>
    <h2 {...{
      "id": "enseignants"
    }}>{`Enseignants`}</h2>
    <IndicatorReference id="4.c.2" mdxType="IndicatorReference">
      <p>{`Les enseignants sont la pierre angulaire de la qualité de l’éducation. Cependant, après 2000, l’élan impulsé en faveur de l’éducation primaire universelle a conduit un grand nombre d’élèves supplémentaires sur les bancs de l’école sans que le recrutement des enseignants ne progresse au même rythme. Cela s’est traduit par des classes surchargées et le recul de la qualité de l’éducation.`}</p>
      <QualityFramework highlighted="teachers" description="En savoir plus sur le cadre relatif à la qualité de l’éducation" mdxType="QualityFramework" />
      <p>{`Le graphique ci-dessous présente le nombre d’élèves par enseignant à l’école. Dans certains pays d’Afrique subsaharienne, ce rapport s’améliore depuis 2000 mais reste plus défavorable que dans les années 1980.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PTR" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Afrique subsaharienne`}</HighlightByIndex>{`, après tous les efforts menés entre 1990 et 2000 en vue de rendre l’école primaire gratuite, les enfants ont intégré l’école plus rapidement que les pays n’ont été capables de recruter de nouveaux enseignants. Le nombre d’élèves par enseignant s’est donc souvent envolé.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En `}<HighlightByIndex index="6" mdxType="HighlightByIndex">{`Amérique du Nord et en Europe`}</HighlightByIndex>{`, en revanche, le rapport est établi à environ 14 élèves par enseignant depuis 2005.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`L’enseignement est une mission exigeante et les enseignants qui n’y sont pas bien préparés arrivent en classe avec un sérieux handicap. Il existe pour les pays deux moyens essentiels d’évaluer leur état de préparation. Il s’agit d’examiner :`}</p>
    <ul>
      <li parentName="ul">{`Premièrement, s’ils sont `}<strong parentName="li">{`qualifiés`}</strong>{`, c’est à-dire s’ils détiennent ou non les diplômes universitaires requis selon les normes nationales.`}</li>
      <li parentName="ul">{`Deuxièmement, s’ils sont `}<strong parentName="li">{`formés`}</strong>{`, ce qui, indépendamment de leurs qualifications, permet d’évaluer s’ils ont suivi ou non une formation des enseignants appropriée.`}</li>
    </ul>
    <IndicatorReference id="4.c.1" mdxType="IndicatorReference">
      <p>{`Pour un enseignant, être formé signifie avoir reçu une formation permettant d’acquérir des `}<Tooltip title="Pedagogical knowledge" content="Knowledge on approaches, methods and techniques of teaching." mdxType="Tooltip">{`connaissances pédagogiques`}</Tooltip>{` et des `}<Tooltip title="Professional knowledge" content="Knowledge on, for example, the legal frameworks governing the teaching profession." mdxType="Tooltip">{`connaissances professionnelles`}</Tooltip>{`. Certains programmes portent également sur la `}<Tooltip title="Content knowledge" content="Knowledge of the curriculum, subject matter and use of relevant materials." mdxType="Tooltip">{`connaissance de contenu`}</Tooltip>{`. Mais le type de formation requise diffère selon les pays, et les informations en la matière sont rares. En raison de ces différences, l’indicateur relatif aux enseignants formés `}{`[4.c.1]`}{` est l’un des moins comparables du cadre de suivi de l’ODD 4, alors qu’il s’agit d’une dimension essentielle de l’éducation de qualité.
À l’échelle mondiale, la majorité des enseignants sont qualifiés, formés, voire les deux. En Amérique latine et dans les Caraïbes, par exemple, 80 % des enseignants sont formés.`}</p>
      <p>{`En `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Afrique subsaharienne`}</HighlightByIndex>{`, en revanche, le pourcentage d’enseignants formés est passé de 85 % en 2000 à 64 % en 2022.`}</p>
      <Figure id="TRAINED" mdxType="Figure" />
      <p>{`Il n’existe pas de solution miracle pour les pays en pénurie d’enseignants qualifiés et formés. Beaucoup manquent d’enseignants qualifiés pour répondre à la demande en raison du faible nombre de diplômés du tertiaire en général. Cela montre à quel point les problèmes peuvent se répercuter les uns sur les autres au sein d’un système éducatif : des problèmes à un certain niveau, comme un faible taux d’achèvement des études secondaires, peuvent facilement entraîner d’autres problèmes ailleurs, et se traduire par exemple par un nombre restreint d’enseignants formés pour les prochaines générations d’élèves.`}</p>
    </IndicatorReference>
    <h2 {...{
      "id": "infrastructure"
    }}>{`Infrastructure`}</h2>
    <IndicatorReference id="4.a.1e" mdxType="IndicatorReference">
      <p>{`Un environnement propice à l’apprentissage doit être équipé d’installations d’approvisionnement en `}<strong parentName="p">{`eau`}</strong>{` et d’assainissement adéquates, en particulier pour les filles. Cependant, un accès élémentaire à l’eau n’était assuré que dans 53 % des écoles primaires en Afrique subsaharienne, 77 % en Asie de l’Est et du Sud-Est et 86 % en Asie centrale et du Sud.`}</p>
      <QualityFramework highlighted="material" description="En savoir plus sur le cadre relatif à la qualité de l’éducation" mdxType="QualityFramework" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1f" mdxType="IndicatorReference">
      <p>{`Plus particulièrement, les installations `}<strong parentName="p">{`sanitaires`}</strong>{` qui manquent d’intimité peuvent sembler peu sûres, tandis que celles qui ne permettent pas d’observer une bonne hygiène menstruelle peuvent empêcher les filles d’aller à l’école. L’accès à des toilettes séparées est donc une mesure politique essentielle pour éviter que la scolarisation tardive ne se généralise à l’école primaire et que les filles abandonnent l’école au fil du temps.`}</p>
      <p>{`Le graphique ci-dessous montre que moins de la moitié des écoles primaires sont pourvues d’installations sanitaires non mixtes, non seulement en Afrique subsaharienne mais aussi dans des pays asiatiques comme l'Afghanistan, la RDP lao et le Népal. En Indonésie, seuls 43 % des écoles primaires, 56 % des établissements du premier cycle du secondaire et 65 % de ceux du deuxième cycle du secondaire offrent un accès à des sanitaires séparés.`}</p>
      <Figure id="SANITATION" mdxType="Figure" />
    </IndicatorReference>
    <IndicatorReference id="4.a.1a" mdxType="IndicatorReference">
      <p>{`L’`}<strong parentName="p">{`électricité`}</strong>{` est un besoin essentiel pour disposer d’un environnement d’apprentissage de qualité, pourtant de nombreux pays n’y ont pas accès. Dans les pays à faible revenu, 73 % des écoles primaires et 58 % des établissements du deuxième cycle du secondaire ne disposent pas de l’électricité.`}</p>
      <p>{`Comme l’illustre le graphique ci-dessous, moins de la moitié des écoles primaires ont accès à l’électricité au Nicaragua et au Pakistan. Dans certains pays d’Afrique subsaharienne, l’accès à l’électricité est très rare dans les écoles : 12 % des écoles en disposent à Madagascar, et seulement 4 % au Tchad. Sans électricité, l’éclairage peut être insuffisant et les enseignants ne peuvent pas utiliser d’ordinateurs pour les tâches administratives ou la formation. L’absence d’électricité signifie qu’il n’y aura pas de ventilation, de climatisation ou de chauffage, ce qui peut rendre les salles de classe impropres à l’enseignement et à l’apprentissage.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.a.1b" mdxType="IndicatorReference">
      <p>{`L’absence d’électricité signifie également qu’il est impossible de se servir des technologies et d’`}<strong parentName="p">{`Internet`}</strong>{`. Cependant, l’électricité n’est pas le seul facteur à ralentir la diffusion d’Internet. Dans les pays à revenu intermédiaire, 37 % des écoles primaires et 22 % des établissements de deuxième cycle du secondaire disposent de l’électricité mais pas d’Internet. Même si les écoles sont raccordées à une source d’électricité, les surtensions et baisses de tension sont fréquentes. La connectivité à Internet dépend des infrastructures de télécommunications nationales, de la situation géographique et des moyens financiers de l’école pour payer ce service.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="ELECTRIC" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="LKA" mdxType="ScrollySection">
          <p>{`Au `}<strong parentName="p">{`Sri Lanka`}</strong>{`, si la totalité des écoles primaires sont raccordées à l’électricité, seules 41 % ont accès à Internet. Il en va de même pour 34 % des écoles au Turkménistan et 32 % en Jordanie.`}</p>
        </ScrollySection>
        <ScrollySection id="ECU,URY" mdxType="ScrollySection">
          <p>{`Dans de nombreux pays d’Amérique latine, des écoles sont privées d’électricité et d’Internet. Seules environ 43 % des écoles en `}<strong parentName="p">{`Équateur`}</strong>{` et 63 % en Argentine peuvent affirmer avoir accès aux deux. La seule exception est l’`}<strong parentName="p">{`Uruguay`}</strong>{`, où 100 % des écoles primaires sont équipées des deux.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "violence"
    }}>{`Violence`}</h2>
    <p>{`Les actes de violence ou les menaces en milieu scolaire peuvent être de nature psychologique, physique ou sexuelle. Ils peuvent se produire dans l’enceinte des établissements scolaires mais aussi sur le trajet de l’école, à la maison ou dans le cyberespace. Ces violences naissent souvent d’un rapport de force inégal et découlent fréquemment de normes et de stéréotypes négatifs en matière de genre.`}</p>
    <p>{`Si l’attention est généralement retenue par les événements extrêmes, comme les fusillades, ce sont les formes de violence plus ordinaires et qui passent souvent inaperçues, telles que le harcèlement, qui ont les effets les plus néfastes sur l’éducation des enfants et des adolescents. Les données sur ces sujets sont encore insuffisantes car les questions posées pour recueillir les données proposent des définitions diverses du comportement violent, portent sur des périodes différentes, ou s’appuient sur des choix de réponse, des dispositions en matière de confidentialité ou des protocoles de déontologie impossibles à comparer. À ceci s’ajoute le fait que certaines formes de violence, comme le harcèlement homophobe, font moins souvent l’objet de signalement en raison de leur caractère tabou.`}</p>
    <QualityFramework highlighted="school" description="En savoir plus sur le cadre relatif à la qualité de l’éducation" mdxType="QualityFramework" />
    <IndicatorReference id="4.a.2" mdxType="IndicatorReference">
      <p>{`Le `}<strong parentName="p">{`harcèlement`}</strong>{` est la forme de violence en milieu scolaire la plus étudiée. Il peut s’agir de violences physiques, de violences verbales ou de l’intention de causer des dommages psychologiques par l’humiliation et l’exclusion. La figure ci-après montre qu’au moins un adolescent sur trois, davantage parmi les garçons que chez les filles, est victime de harcèlement dans la plupart des pays.`}</p>
      <Figure id="BULLY_FIGHT" mdxType="Figure" />
    </IndicatorReference>
    <p>{`La `}<strong parentName="p">{`violence physique`}</strong>{` englobe le fait d’être mêlé à des bagarres à l’école, de porter une arme (arme à feu, couteau ou batte) ou d’en être menacé dans l’enceinte de l’école, de faire partie d’une bande ou d’en être complice ou bien de subir des violences physiques de la part d’un membre du personnel scolaire.`}</p>
    <p>{`Dans 24 pays, au moins 10 % des garçons de 15 ans ont déclaré avoir été impliqués dans des bagarres physiques trois fois ou plus en 2022, et la prévalence a atteint plus de 30 % en Arménie.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Dans le même temps, les données comparables sur le plan international en ce qui concerne les indicateurs relatifs aux enseignants sont étonnamment rares. Relativement peu de pays effectuent le dénombrement, même élémentaire, des enseignants, et ils ne prennent pas en compte les heures d’enseignement, les enseignants occupant des postes administratifs et d’autres réalités complexes. Il est nécessaire d’établir une typologie des normes concernant les enseignants formés afin de pouvoir correctement repérer les lacunes et porter cette question à un niveau mondial.`}</p>
    <p>{`La mise en place d'établissements scolaires « adaptés aux enfants, aux personnes handicapées et aux deux sexes » et d'un « cadre d'apprentissage sûr, non violent, inclusif et efficace pour tous » est un défi dans de nombreux pays, comme l’illustre cet article. Dans le même temps, il est difficile de mesurer les progrès en la matière car l’indicateur mondial correspondant n’est pas une mesure simple, mais regroupe plusieurs dimensions.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.oecd-ilibrary.org/education/education-at-a-glance_19991487"
          }}>{`Education at a glance – OECD`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theirworld.org/news/safe-schools-report-reveals-crises-challenges-action-needed-to-educate-children"
          }}>{`Safe Schools Report – Their World`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000232107"
          }}>{`School-related gender-based violence is preventing the achievement of quality education for all`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;