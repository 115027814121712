
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Access";
export const lead = "Globally, 88% of children complete primary school, 78% of adolescents complete lower secondary school and 59% of youth complete upper secondary school.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const Highlight = makeShortcode("Highlight");
const Tooltip = makeShortcode("Tooltip");
const PageLink = makeShortcode("PageLink");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_ACCESS" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`The world failed on its promise for all children to go to primary school by 2015. The new education goal, SDG 4, has set the level of ambition a step higher, calling for all young people to complete secondary school by the 2030 deadline. To achieve this, all children of school starting age should have begun school in 2018, but in reality, only 70% did so in low-income countries for instance.`}</p>
    </blockquote>
    <h2 {...{
      "id": "pre-school-attendance"
    }}>{`Pre-school attendance`}</h2>
    <IndicatorReference id="4.2.2" mdxType="IndicatorReference">
      <p><strong parentName="p">{`Attending pre-school`}</strong>{` is a crucial starting point for children's journey through education. Yet, in low-income countries, only 44% of children are enrolled in pre-primary education the year before primary.`}</p>
    </IndicatorReference>
    <p>{`Since 2000, progress in pre-school attendance has been slow but steady across most countries, including in many of the poorest, but many are still left behind. Globally, 75% of children now attend pre-primary education compared to 64% in 2003.`}</p>
    <p>{`Some countries have seen vast increases. In Kyrgyzstan, for example, the participation rate increased from 42% in 2000 to 87% in 2024. In the Lao PDR, it increased from 10% to 89% over the same period.`}</p>
    <p>{`Both `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Guatemala`}</HighlightByIndex>{` and `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Niger`}</HighlightByIndex>{` highlighted below are examples of countries that have seen a steady increase in pre-primary attendance, even though there are huge gaps between the two today. Select your country to see its progress.`}</p>
    <Figure id="PREPRIM" mdxType="Figure" />
    <h2 {...{
      "id": "over-age-participation"
    }}>{`Over-age participation`}</h2>
    <IndicatorReference id="4.1.5" mdxType="IndicatorReference">
      <p>{`We do not talk enough about the fact that in a number of countries more than a quarter of primary school students are `}<strong parentName="p">{`over-age`}</strong>{` when they enrol, meaning they are significantly older than students in their grade are supposed to be. This has significant implications for their future, increasing the likelihood of further repeating grades, failing exams and eventually dropping out of school.`}</p>
    </IndicatorReference>
    <p>{`This graph looks at `}<strong parentName="p">{`education participation`}</strong>{`, showing how old children, adolescents and youth are when they attend pre-primary, primary, lower and upper secondary, as well as post-secondary education. The spread of colour indicates how many children are over-age for their education level. The grey shaded area shows the percentage of children and adolescents who are out of school by age.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OVERAGE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="KHM" mdxType="ScrollySection">
    In <Highlight mdxType="Highlight">Cambodia</Highlight>, most children should be in school at 10
    years old, yet 21% are still out of school at that age. Many are too old for
    their grade. There are 14-year-olds who are still attending primary school,
    for instance.
  </ScrollySection>
  <ScrollySection id="LBR" mdxType="ScrollySection">
    The over-age rate is even higher in countries such as{" "}
    <Highlight mdxType="Highlight">Liberia</Highlight> with 28% of 10 year olds in pre-school and
    20% of 18 year olds still in primary school.
  </ScrollySection>
  <ScrollySection id="NPL" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Nepal</Highlight> faces the same challenge
  </ScrollySection>
  <ScrollySection id="MWI" mdxType="ScrollySection">
    as does <Highlight mdxType="Highlight">Malawi</Highlight>.
  </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "out-of-school-children"
    }}>{`Out-of-school children`}</h2>
    <IndicatorReference id="4.1.4" mdxType="IndicatorReference">
      <p>{`Globally, there are 251 million `}<strong parentName="p">{`out of school`}</strong>{` children and youth. A `}<a parentName="p" {...{
          "href": "www.education-estimates.org"
        }}>{`new model developed by the UNESCO Institute for Statistics and the Global Education Monitoring Report`}</a>{` suggests that the primary out-of-school rate has been `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000382577"
        }}>{`declining faster than previously thought`}</a>{`, even if the rate of decline has slowed down in recent years.`}</p>
      <p>{`Conflict is a key reason for stagnation. Unfortunately conflict also means that the availability and quality of data deteriorates, although some countries continue to report estimates. More and sustained information over time is also needed to properly assess the recent impact of COVID-19 on enrolment.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
  <ScrollySticky mdxType="ScrollySticky">
    <Figure id="OOSRATE" mdxType="Figure" />
  </ScrollySticky>
  <ScrollySection id="ECU,PRY" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Ecuador</Highlight> and <Highlight mdxType="Highlight">Paraguay</Highlight> show very
    different directions of progress. In lower and upper secondary education, the
    out-of-school rate in Ecuador has plummeted, while it has climbed in
    Paraguay.
  </ScrollySection>
  <ScrollySection id="ERI,ETH" mdxType="ScrollySection">
    <Highlight mdxType="Highlight">Eritrea</Highlight> and <Highlight mdxType="Highlight">Ethiopia</Highlight> also show
    very different rates of progress. The after-effects of conflict in Eritrea
    visibly took a toll on education access, while Ethiopia has prioritised
    education in its budget, which led to the rapid reduction in out-of-school
    children over a short period of time.
  </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`Another reason for a lack of progress in reducing out-of-school numbers is population growth.`}</p>
    <p>{`In sub-Saharan Africa, the primary `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`school-aged population`}</HighlightByIndex>{` has nearly doubled between 2000 and 2023. As a result, even though the `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`rate of out-of-school children`}</HighlightByIndex>{` has nearly halved during this period, the `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`number of children out-of-school`}</HighlightByIndex>{` has barely changed.`}</p>
    <Figure id="OOSREL" mdxType="Figure" />
    <h2 {...{
      "id": "completion"
    }}>{`Completion`}</h2>
    <IndicatorReference id="4.1.2" mdxType="IndicatorReference">
      <p>{`The ultimate objective of SDG 4 is universal upper secondary `}<strong parentName="p">{`completion`}</strong>{` and not just enrolment. This is despite the fact that, in many countries, education is only free and compulsory up to lower secondary education at most.`}</p>
      <p>{`The world is not even on track to achieve universal primary completion by the deadline, let alone upper secondary completion. On current trends, only about six in ten young people will be finishing secondary school in 2030.`}</p>
      <p>{`The indicator refers to children who may complete education levels up to 3 or 5 years later than the official graduation age – this can be defined as `}<Tooltip title="Timely completion" content="Children who complete education levels up to 3 or 5 years beyond the final grade" mdxType="Tooltip">{`timely completion`}</Tooltip>{`. But many children complete even later than that - this can be defined as `}<Tooltip title="Ultimate completion" content="Children who complete education levels up to 8 years beyond the final grade" mdxType="Tooltip">{`ultimate completion`}</Tooltip>{`.`}</p>
      <p>{`Looking at the graph below, in Kenya, for example, there is a nearly 20 percentage point gap between `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`timely`}</HighlightByIndex>{` (75%) and `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`ultimate`}</HighlightByIndex>{` completion (94%) at the primary level. Mozambique shows a similar gap.`}</p>
      <Figure id="COMPLETION" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`When children enrol, at what age and whether or not they graduate are crucial policy concerns.`}</p>
    <p>{`The next themes on this site look at how close we are to achieving `}<PageLink name="equity" mdxType="PageLink">{`equity`}</PageLink>{` in education, what students are `}<PageLink name="learning" mdxType="PageLink">{`learning`}</PageLink>{`, how good their education `}<PageLink name="quality" mdxType="PageLink">{`quality`}</PageLink>{` is, and who `}<PageLink name="finance" mdxType="PageLink">{`finances`}</PageLink>{` it.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-estimates.org"
          }}>{`Visualizing Indicators of Education for the World (VIEW)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000382577"
          }}>{`New estimation confirms out-of-school population is growing in sub-Saharan Africa`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://resourcecentre.savethechildren.net/library/education-against-odds-meeting-marginalised-childrens-demands-quality-education"
          }}>{`Education above the odds – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining data on out-of-school children, Completion and learning to offer a more comprehensive view on SDG 4`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;