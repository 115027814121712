
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Équité";
export const lead = "Des progrès ont été accomplis à l’échelle mondiale en matière de parité entre les sexes dans l’éducation, mais les écarts demeurent profonds entre le milieu rural et le milieu urbain, et entre les riches et les pauvres.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const PageLink = makeShortcode("PageLink");
const IndicatorReference = makeShortcode("IndicatorReference");
const Tooltip = makeShortcode("Tooltip");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_EQUITY" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`La volonté de ne laisser personne de côté, qui recoupe l’ensemble des Objectifs de développement durable (ODD), est expressément mentionnée dans la 5e cible de l’ODD relatif à l’éducation, l’ODD 4. Il s’agit de faire en sorte que les responsables politiques aident en priorité ceux qui accusent le plus grand retard. `}</p>
    </blockquote>
    <p>{`Pour être en mesure de déterminer si les écarts en matière d’éducation sont en passe d’être comblés, nous avons besoin de données ventilées `}<strong parentName="p">{`par niveau de revenu, sexe, âge, race, groupe ethnique, statut migratoire, handicap, situation géographique et d’autres caractéristiques`}</strong>{` selon les contextes nationaux, que fournissent les enquêtes auprès des ménages.`}</p>
    <p>{`Depuis 2010, la `}<a parentName="p" {...{
        "href": "https://www.education-inequalities.org/"
      }}>{`World Inequality Database on Education`}</a>{` s’appuie sur les enquêtes menées auprès des ménages pour faire apparaître l’ampleur des écarts en matière d’éducation dans différents pays. Cet article récapitule quelques-uns des faits et tendances majeurs en matière d’inégalité dans l’éducation, tandis que certains aspects complémentaires sont abordés dans l’article sur le `}<PageLink name="finance" mdxType="PageLink">{`financement`}</PageLink>{`.`}</p>
    <h2 {...{
      "id": "égalité-des-genres"
    }}>{`Égalité des genres`}</h2>
    <IndicatorReference id="4.5.1" mdxType="IndicatorReference">
      <p>{`Le graphique ci-après présente les inégalités de l’éducation entre groupes, tels que les filles et les garçons, ou les plus démunis et les plus riches. Il mesure ces écarts en appliquant un `}<Tooltip title="indice de parité" content="Si un indice de parité est proche de 0, cela signifie que le groupe défavorisé est très loin derrière ; si l’indice est proche de 1, la parité est atteinte ; si l’indice est proche de 2, le groupe favorisé est le plus en retard. Si, par exemple, l’indice de parité est de 0,5, cela peut signifier que 30 % des filles et 60 % des garçons sont scolarisés. Un indice de 1,5 signifierait qu’il y a 30 % de garçons et 60 % de filles scolarisés." mdxType="Tooltip">{`indice de parité`}</Tooltip>{`.`}</p>
      <p>{`Bien que ce fait soit rarement salué, la `}<Tooltip title="parité entre les sexes " content="Lorsqu’il y a autant de filles que de garçons scolarisés" mdxType="Tooltip">{`parité entre les sexes`}</Tooltip>{` n’a cessé de s’améliorer au cours de la dernière génération. Dans de nombreuses régions du monde, généralement les plus riches, les filles et les jeunes femmes surpassent en nombre les garçons et les jeunes hommes dans les écoles et les universités.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
  <Figure id="GPITREND" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="income,0" mdxType="ScrollySection">
          <p>{`Toute ligne en-deçà de `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`la zone grisée`}</HighlightByIndex>{` indique que le nombre de garçons et de jeunes gens scolarisés est supérieur à celui des filles.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,1" mdxType="ScrollySection">
          <p>{`En `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Afrique subsaharienne`}</HighlightByIndex>{`, l’enseignement tertiaire compte encore moins de 4 jeunes femmes pour 5 hommes.`}</p>
        </ScrollySection>
        <ScrollySection id="geo,2" mdxType="ScrollySection">
          <HighlightByIndex index="2" mdxType="HighlightByIndex">L’Asie centrale et l’Asie du Sud</HighlightByIndex>, où les filles accusaient un important retard en 1990, ont désormais atteint la parité entre les sexes dans le l’enseignement primaire au l’enseignement tertiaire, principalement grâce aux spectaculaires progrès réalisés en Inde. En conséquence, la région a dépassé <HighlightByIndex index="0" mdxType="HighlightByIndex">l’Afrique subsaharienne</HighlightByIndex> en termes de progrès vers la réalisation de la parité entre les sexes.
        </ScrollySection>
      </ScrollyArea>
      <p>{`À l’échelle mondiale, d’importants progrès ont été réalisés depuis 2000. Cependant, un examen approfondi au niveau des pays révèle des écarts persistants : seuls deux pays sur trois ont atteint la parité entre les sexes concernant la scolarisation dans le primaire, moins d'un pays sur deux dans le premier cycle du secondaire et un pays sur cinq dans le second cycle du secondaire.`}</p>
      <p>{`Bien qu’il ait diminué au moins de moitié depuis 2000 à l’enseignement primaire, secondaire et tertiaire, le pourcentage de pays affichant de `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`fortes disparités au détriment des filles `}</HighlightByIndex>{` est toujours de 3 % dans le primaire, de 6 % dans le premier cycle du secondaire et de 12 % dans le second cycle du secondaire.`}</p>
      <p>{`Nous ne devons pas oublier les garçons en évoquant l’égalité des genres. S’il est moins courant pour des pays de présenter d' `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`importantes inégalités au détriment des garçons`}</HighlightByIndex>{` dans le primaire, ces inégalités sont bien plus répandues dans le second cycle du secondaire et au-delà.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PARITYCOUNT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Dans ce graphique, plus la partie bleue de la barre est large et foncée, plus nombreux sont les pays `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`comptant davantage de garçons que de filles parmi les enfants déscolarisés`}</HighlightByIndex>{` than girls. Plus la partie verte de la barre est large et foncée, plus nombreux sont les pays qui `}<HighlightByIndex index="2" mdxType="HighlightByIndex">{`comptent davantage de filles déscolarisées que de garçons `}</HighlightByIndex>{` dans cette situation.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
  
Dans l’enseignement tertiaire, par exemple, presque deux tiers des pays présentent de <HighlightByIndex index="0" mdxType="HighlightByIndex"> profondes disparités au détriment des garçons</HighlightByIndex>.
        </ScrollySection>
      </ScrollyArea>
      <h2 {...{
        "id": "richesse"
      }}>{`Richesse`}</h2>
      <p>{`Dans l’ensemble, les écarts entre filles et garçons en matière d’éducation ne sont pas aussi profonds que les écarts entre le milieu rural et le milieu urbain, ou entre les riches et les pauvres, comme l’ont fait ressortir les enquêtes auprès des ménages.`}</p>
      <p>{`Au Bangladesh, par exemple, si 92 % des `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`plus riches `}</HighlightByIndex>{` suivent un enseignement primaire, ils ne sont plus que 70 % parmi les `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`plus pauvres`}</HighlightByIndex>{`. Au Nigéria, 94 % des plus riches fréquentent le premier cycle de l’enseignement secondaire, contre seulement 27 % parmi les plus pauvres.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="PSECWGAP" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Les points rouges représentent le pourcentage d’enfants ou de jeunes les `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`plus pauvres`}</HighlightByIndex>{` allant à l’école en comparaison avec les `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`plus riches`}</HighlightByIndex>{` – représentés en bleu. Vous pouvez modifier le niveau d’enseignement depuis le primaire jusqu’au premier cycle de l’enseignement secondaire et au-delà en haut du graphique.`}</p>
        </ScrollySection>
        <ScrollySection id="SRB" mdxType="ScrollySection">
          <p>{`Dans l’enseignement tertiaire, les écarts les plus importants concernent les pays à revenu intermédiaire. Ainsi, en `}<strong parentName="p">{`la Serbie`}</strong>{`, 77 % des plus riches mais moins de 28 % des plus pauvres ont suivi une forme quelconque d’enseignement post-secondaire. `}</p>
        </ScrollySection>
        <ScrollySection id="COL" mdxType="ScrollySection">
          <p>{`En `}<strong parentName="p">{`Colombie`}</strong>{`, ces chiffres s’élevaient respectivement à 64 et 7 %.`}</p>
        </ScrollySection>
      </ScrollyArea>
      <p>{`Il est encore plus frappant d’observer la façon dont ces écarts évoluent à mesure que les enfants poursuivent leurs études.`}</p>
      <p>{`L’examen ci-après des écarts `}<strong parentName="p">{`entre les sexes`}</strong>{` au `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Bénin`}</HighlightByIndex>{` montre à quel point les filles abandonnent l’école à mesure que le niveau d’enseignement s’élève.`}</p>
      <p>{`Par ailleurs, sélectionner la `}<strong parentName="p">{`richesse`}</strong>{` montre combien les écarts s’accumulent au cours de la scolarité au détriment des plus pauvres, parmi lesquels très peu vont au-delà du second cycle du secondaire.`}</p>
      <Figure id="WLGPI" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Il est rare qu’un enfant ne relève que d’une seule catégorie, il est donc essentiel d’examiner aussi comment les caractéristiques individuelles, telles que le sexe, interagissent avec d’autres, telles que la richesse, comme l’illustre la figure suivante.`}</p>
    <p>{`En Afrique subsaharienne, les écarts entre les `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`plus pauvres`}</HighlightByIndex>{` et les `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`plus riches`}</HighlightByIndex>{` se creusent au cours de la scolarité, puisque pour 100 garçons, moins de 35 filles parmi les plus pauvres contre près de 100 parmi les plus riches poursuivent des études tertiaires.`}</p>
    <p>{`En Asie de l’Est et du Sud-Est, en revanche, les points remontent en s’éloignant de la ligne grise car les écarts entre les sexes se font au détriment des garçons les plus pauvres. Au niveau de l’enseignement tertiaire, les filles les plus riches n’ont environ que 35 % de chances de plus de suivre des études par rapport à leurs homologues masculins, mais les filles les plus pauvres ont 50% de chances de plus de le faire.`}</p>
    <Figure id="GPIBYWEALTH" mdxType="Figure" />
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Il existe des inégalités d’une ampleur inacceptable entre pays et entre certains groupes au sein de ces pays. Disposer de données ventilées de haute qualité est une condition indispensable pour concevoir les stratégies appropriées qui permettront de faire reculer ces inégalités et, au bout du compte, de ne laisser personne de côté dans nos efforts pour atteindre l’ODD 4.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.education-inequalities.org/"
          }}>{`Worldwide Inequalities Database on Education (WIDE)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.savethechildren.org.uk/content/dam/gb/reports/policy/still-left-behind-low-res.pdf"
          }}>{`Still left behind – Save the Children`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2024genderreport"
          }}>{`Technology on her terms – GEM Gender Report 2024`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;