
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Aprendizaje";
export const lead = "En al menos 15 países del África subsahariana, más de la mitad de los estudiantes no alcanzan el nivel mínimo de competencia en lectura al final de la educación primaria.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_LEARNING" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Lograr que los niños asistan a la escuela no es suficiente. Sin dejar de lado los muchos otros fines beneficiosos que las escuelas persiguen, y deben perseguir, los estudiantes necesitan ser capaces de leer y efectuar cálculos básicos. Estos son los cimientos de otros aprendizajes, del empoderamiento y el bienestar individuales, así como de los resultados sociales, políticos y económicos.`}</p>
    </blockquote>
    <h2 {...{
      "id": "niños-y-jóvenes"
    }}>{`Niños y jóvenes`}</h2>
    <IndicatorReference id="4.1.1" label="4.1.1a" mdxType="IndicatorReference">
      <p>{`En algunos de los países más pobres del mundo, muchos niños carecen de aptitudes básicas de lectura y matemáticas. De las evaluaciones de lectura en los primeros cursos escolares en los estados del norte de Nigeria, como Kaduna y Sokoto, y en provincias de la República Democrática del Congo, como Equateur y Katanga, por ejemplo, se desprende que más del 80% de los alumnos de tercer grado de primaria no podía siquiera leer `}<strong parentName="p">{`una sola palabra`}</strong>{` de un texto estructurado.`}</p>
      <Figure id="EGRA" mdxType="Figure" />
    </IndicatorReference>
    <p>{`Estos datos no incluyen a los niños que no están escolarizados. Las encuestas realizadas en los hogares pueden tomar en cuenta las aptitudes tanto de los niños que asisten a la escuela, como de los que no lo hacen.`}</p>
    <p>{`En los gráficos que figuran a continuación se examinan diferentes regiones de la India y el Pakistán, de acuerdo con evaluaciones dirigidas por los ciudadanos.`}</p>
    <p>{`Las `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`líneas rojas`}</HighlightByIndex>{` muestran el porcentaje de niños `}<strong parentName="p">{`escolarizados`}</strong>{` que tienen aptitudes básicas de lectura, por grado, mientras que las `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`líneas azules`}</HighlightByIndex>{` muestran el porcentaje de todos los niños que tienen aptitudes básicas de lectura, `}<strong parentName="p">{`escolarizados o no, por la edad que corresponde a su grado`}</strong>{`.`}</p>
    <p>{`Los espacios entre las dos líneas son un recordatorio de que, en los países con altas tasas de niños no escolarizados, los responsables de la formulación de políticas ven solo una imagen parcial si se limitan a supervisar el aprendizaje de los que asisten a la escuela. Es importante señalar que el nivel de aptitud constatado es muy bajo. Incluso en las regiones con mejores resultados, siempre hay algunos adolescentes en la escuela secundaria que no pueden leer un texto de segundo grado.`}</p>
    <Figure id="LEARN_AGE_GRADE" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Se estima que cerca del 40% de los niños en edad de asistir a la escuela primaria carecen de aptitudes básicas de lectura. Este es el caso del 70% de los de África Subsahariana, pero solo del 3% de los niños de Europa y América del Norte.`}</p>
    </IndicatorReference>
    <IndicatorReference id="4.1.0" label="4.1.0" mdxType="IndicatorReference">
      <p>{`En muchos países, no hay garantía de que los niños sepan leer aun cuando han completado la escuela primaria. De aquellos niños que han completado la escuela primaria en el mundo, se estima que cuatro sobre diez no cuentan con habilidades básicas de lectura. Esta cifra aumenta a ocho de cada diez niños en países de bajos ingresos, como lo muestra el siguiente gráfico.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LEARN_REACHFINAL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`Las `}<HighlightByIndex index="-1" mdxType="HighlightByIndex">{`partes grises`}</HighlightByIndex>{` muestran el porcentaje de niños que finalizan la escuela primaria, pero no alcanzan un nivel mínimo de competencia en lectura. Las `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`partes rojas`}</HighlightByIndex>{` muestran a los alumnos que finalizan la primaria y alcanzan un nivel mínimo de competencia, y las `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`partes azules`}</HighlightByIndex>{` a los niños que no terminan la educación primaria.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <p>{`En el plano nacional, en Chad y Nigeria, alrededor del 90% de los jóvenes que abandonaron la escuela tras cinco o seis años no estaba alfabetizado. Sin embargo, las tasas de algunos países son mucho mejores. Así, casi todos los que salieron de la escuela tras el quinto o el sexto grado en Burundi, Costa Rica y Honduras estaban alfabetizados.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="LITBYYRS" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="BFA" mdxType="ScrollySection">
        <p>{`En `}<strong parentName="p">{`Kenya`}</strong>{`, por ejemplo, uno de cada cuatro están alfabetizados `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`con cuatro años de escolaridad`}</HighlightByIndex>{`, pero la proporción asciende a más de tres de cada cuatro en el caso de los que han cursado `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`entre cinco y seis años de estudios`}</HighlightByIndex>{`, el equivalente de la educación primaria.`}</p>
      </ScrollySection>
      <ScrollySection id="NER" mdxType="ScrollySection">
        <p>{`En `}<strong parentName="p">{`el Burkina Faso`}</strong>{`, `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`después de cuatro años de escolarización`}</HighlightByIndex>{` casi ningún niño es capaz de leer, pero más de la mitad adquiere aptitudes básicas de alfabetización si finaliza `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`el ciclo completo de educación primaria`}</HighlightByIndex>{`.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <p>{`Para poder comparar el aprendizaje entre países, es necesario que las evaluaciones o pruebas se ajusten a las normas internacionales empleando una definición común. Recientemente se han establecido normas de este tipo para las evaluaciones internacionales comparadas, en las que alumnos de varios países realizan el mismo examen. No obstante, aún hay menos iniciativas de este tipo para las evaluaciones nacionales.`}</p>
    <p>{`Una evaluación que se lleva a cabo cada cuatro años, el Estudio Internacional de Tendencias en Matemáticas y Ciencias (TIMSS, por sus siglas en inglés), nos permite observar el progreso a lo largo del tiempo entre los alumnos de cuarto y octavo grado. Ésta pone de relieve los lugares en los que los avances han sido particularmente lentos. En Egipto, por ejemplo, el porcentaje de alumnos de octavo grado que alcanzaba las competencias mínimas en matemáticas aumentó del 24% en 2003 a apenas el 27% en 2019.`}</p>
    <p>{`En algunos países, como `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`Malasia`}</HighlightByIndex>{`, la proporción de estudiantes que alcanza niveles mínimos de aprendizaje se ha reducido con el tiempo.`}</p>
    <p>{`En otros, como `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`Bahrein`}</HighlightByIndex>{`, el progreso ha sido rápido: la proporción de estudiantes que alcanza el nivel mínimo de competencia aumentó más del triple entre 2003 y 2019.`}</p>
    <Figure id="PIRLSGAIN" mdxType="Figure" />
    <IndicatorReference id="4.1.1" label="4.1.1b" mdxType="IndicatorReference">
      <p>{`Contrariamente a la idea de la mayoría, las brechas de género en el aprendizaje no son amplias, ya que no superan los diez puntos porcentuales en casi ningún país en la educación primaria, por ejemplo.`}</p>
      <p>{`Pero las brechas de aprendizaje entre los países son enormes. En los países de África Subsahariana, que tienden a niveles inferiores de las aptitudes de lectura en la educación primaria, la proporción de alumnos que alcanzan competencias mínimas oscilan entre apenas el 4% en Burundi y el 76% en el Gabón (ambas provenientes del PASEC de 2019).`}</p>
      <p>{`En cuanto a las matemáticas en la educación primaria en los países de América Latina, las tasas van de apenas el 8% en la RDP Lao al 91% en el Vietnam (ambas provenientes del SEA-PLM de 2019).`}</p>
      <Figure id="ASSESS" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "adultos"
    }}>{`Adultos`}</h2>
    <IndicatorReference id="4.6.2" mdxType="IndicatorReference">
      <p>{`Entre 2000 y 2023, el porcentaje de adultos alfabetizados aumentó del 81% al 87% a nivel mundial, mientras que el de jóvenes alfabetizados pasó del 87% al 93%.`}</p>
      <p>{`A pesar de estas mejoras, el analfabetismo sigue estando generalizado en muchos países, especialmente entre las mujeres. En América Latina y el Caribe, el estancamiento de los progresos a niveles relativamente altos es un indicio de que existen grupos profundamente desfavorecidos "difíciles de alcanzar". En Ecuador, por ejemplo, la tasa de alfabetización de mujeres en zonas rurales, del 83%, no ha variado en los últimos 10 años.`}</p>
      <p>{`En el África Subsahariana, más de 40% de las mujeres adultas (entre 25 y 64 años) no saben leer. En muchos países, los avances se han frenado en niveles muy bajos, como en Malí, donde menos del 17% sabe leer, o han sido lentos, como en la República Unida de Tanzania, que ha presentado un incremento del 72% al 77% en diez años.`}</p>
      <p>{`Si actualmente hay en el mundo más adultos alfabetizados que antes, ello se debe principalmente a que más adultos fueron a la escuela cuando eran jóvenes, y no a que a los que no lo hicieron se les hubiera brindado una segunda oportunidad de aprender. Para sustentar esta afirmación, el siguiente gráfico muestra datos que hacen un seguimiento de las aptitudes de alfabetización de una determinada cohorte, o grupo, a medida que se vuelve mayor.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="LITCOHORT" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En la mayoría de los países, el nivel de alfabetización de una determinada `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`cohorte de adultos`}</HighlightByIndex>{` se estancaba o incluso se reducía con el paso del tiempo debido a que las competencias se infrautilizaban.`}</p>
        </ScrollySection>
        <ScrollySection mdxType="ScrollySection">
          <p>{`En `}<strong parentName="p">{`Burkina Faso`}</strong>{`, la línea azul muestra que la tasa de alfabetización de `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`las mujeres de 20 a 34 años en 2003`}</HighlightByIndex>{`, `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`de 27 a 41 años en 2010`}</HighlightByIndex>{` y `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`de 37 a 62 años en 2021`}</HighlightByIndex>{` se mantuvo relativamente estable. Sin embargo, como hay más mujeres jóvenes aprendiendo a leer y escribir, la línea roja muestra que `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`la tasa de alfabetización entre las mujeres de 20 a 34 años`}</HighlightByIndex>{` ha ido aumentando a lo largo del tiempo.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <IndicatorReference id="4.6.1" mdxType="IndicatorReference">
      <p>{`Las aptitudes de alfabetización que miden estas evaluaciones son muy básicas, ya que muestran si una persona es capaz de leer una frase simple. La alfabetización funcional, que supone que las personas pueden leer e interpretar instrucciones escritas, cartas formales o contratos, es una cuestión diferente.`}</p>
      <p>{`Pocas encuestas internacionales recogen datos sobre la alfabetización funcional, y mucho menos de forma regular.`}</p>
      <p>{`A partir de los datos que tenemos, podemos ver que más de uno de cada 10 adultos de zonas urbanas en Colombia y Viet Nam, y uno de cada cuatro en Bolivia no han adquirido una alfabetización funcional (es decir, están por debajo del nivel 1 de competencia de alfabetización funcional).`}</p>
      <p>{`En `}<strong parentName="p">{`Ghana`}</strong>{`, incluso entre los adultos de zonas urbanas, solo el 39% tiene aptitudes de alfabetización funcional.`}</p>
      <Figure id="LITPROF" mdxType="Figure" />
    </IndicatorReference>
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`Aunque el ODS 4 ha reorientado el énfasis hacia el seguimiento del aprendizaje, muchos países todavía no presentan información acerca de los indicadores de resultados del aprendizaje en lectura y matemáticas. Por otra parte, los países que desean comenzar a emprender evaluaciones e informar sobre el aprendizaje se enfrentan a menudo a obstáculos que les impiden hacerlo de forma eficaz y rentable. No obstante, los datos con los que contamos indican que la mayoría de los alumnos de los países más pobres del mundo no alcanza un nivel mínimo de competencia en las aptitudes más básicas.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://openknowledge.worldbank.org/handle/10986/32553"
          }}>{`Ending learning poverty – what will it take? – World Bank`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://uis.unesco.org/sites/default/files/documents/ip61-combining-data-out-of-school-children-completion-learning-offer-more-comprehensive-view-sdg4.pdf"
          }}>{`Combining Data on Out-of-School Children, Completion and Learning to Offer a More Comprehensive View on SDG 4 – UIS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://unesdoc.unesco.org/ark:/48223/pf0000225660"
          }}>{`EFA GMR 2013/4: Teaching and learning: Achieving quality for all`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gemreportunesco.wordpress.com/2019/05/20/the-learning-assessment-market-pointers-for-countries-part-2/"
          }}>{`The learning assessment market - pointers for countries`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;