
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Finance";
export const lead = "One in three countries did not reach either of the finance benchmarks set in the Education 2030 Framework for Action.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`The ambitious SDG education targets will not be met without additional resources, especially in countries that are the furthest behind.`}</p>
    </blockquote>
    <p>{`The GEM report calculates that in order to achieve the 2030 target for universal basic education, i.e. pre-primary, primary and secondary education, low- and lower-middle-income countries need to spend US$461 billion annually. Many countries are unlikely to increase budgets adequately due to low tax revenues, resulting in an estimated annual financing gap of US$97 billion (21% of the total cost) for achieving national targets between 2023 and 2030. The average gap is US$26 billion (50% of the total) in low-income countries and US$71 billion (17%) in lower-middle-income countries, equivalent to 2.3% of GDP during this period.`}</p>
    <IndicatorReference id="4.5.3" mdxType="IndicatorReference">
      <p>{`Having more resources is not necessarily enough, though; countries spending the same amount in terms of income per capita can have wildly different education results depending on whether they are spending effectively, efficiently and equitably. For instance, out of 78 low- and middle-income countries with available information, only 17 - or one in five - maintained a strong `}<a parentName="p" {...{
          "href": "https://www.unesco.org/gem-report/en/node/80"
        }}>{`equity focus through financing policies`}</a>{`. They were mostly upper-middle-income and Latin American countries.`}</p>
    </IndicatorReference>
    <p>{`The three main sources of education spending are governments, households and donors. This page will focus on the first two. A separate page is devoted to `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/en/articles/finance-aid"
      }}>{`aid to education`}</a>{`.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`In 2022, of the US$ 5.8 trillion spent on education worldwide, only 0.45% is spent in low income countries, while 64% is spent in high income countries, even though the two groups have a roughly equal number of school-age children.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`In the median country, `}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`households`}</HighlightByPalette>{` are covering a quarter of the cost of education. In fact, households pick up a much larger education bill in lower-middle-income countries (44%) than in high-income countries (20%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`Although `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`donors`}</HighlightByPalette>{` contribute 12% of total education spending in low income countries …`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`… `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`donors'`}</HighlightByPalette>{` contributions account for merely 0.3% of total spending globally, which is barely visible in absolute amounts.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "public-expenditure"
    }}>{`Public expenditure`}</h2>
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`The `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
        }}>{`Education 2030 Framework for Action`}</a>{` has set two key finance benchmarks for governments. It calls for them to:`}</p>
      <ul>
        <li parentName="ul">{`allocate at least 4% to 6% of GDP to education, and/or`}</li>
        <li parentName="ul">{`allocate at least 15% to 20% of public expenditure to education.`}</li>
      </ul>
      <p>{`Globally, governments have not strayed far from these norms. The median government spending is 4% of GDP and 12.6% of total public expenditure. Governments in poorer countries with low capacity to generate domestic resources but with large cohorts of children tend to spend less on education as a share of GDP but more as a share of total government spending.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`In Sub-Saharan Africa, government spending on education represents 15.5% of total public expenditure, but 3.5% of GDP. Conversely, Europe and Northern America meets the benchmark as a share of GDP (4.8%) but allocates a low share of total spending to education (10.9%).`}</p>
      <p>{`But, altogether, about one in three countries with recent data did not meet either benchmark. Play these data over time to see how spending fluctuates over the years.`}</p>
    </IndicatorReference>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <p>{`The main source of government revenue is taxation, even in low-income countries receiving a high share of income from grants (i.e. aid). Overall, average tax revenue as a share of GDP was 14% in low-income, 18% in lower-middle-, 22% in upper-middle- and 33% in high-income countries. Even if all countries assigned the same priority to education in their budget, for instance, countries that raise more taxes and other domestic resources will spend more on education.`}</p>
    <p>{`Having said that, the large variation in the amount spent on education between countries with otherwise similar characteristics is an indication that there is nothing pre-determined in these relationships. Some countries choose to spend more on education to achieve their objectives. And we should highlight those that do not.`}</p>
    <h2 {...{
      "id": "household-expenditure"
    }}>{`Household expenditure`}</h2>
    <p>{`Households spend a significant amount to support their children’s education – and more so in poorer than in richer countries.`}</p>
    <p>{`This spending can be in the form of tuition and other fees, but can also be for education goods and services, of which some are required (e.g. textbooks) and others are not (e.g. private lessons).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Where such data is standardized across countries, spectacular evidence emerges of the contribution that households are making to total education spending. This can change the way countries’ efforts on education are understood.`}</p>
      <p>{`In low-income countries, households spend less than 1% of their budgets on education, constrained by economic hardship. In contrast, households in lower-middle-income countries allocate a median of 2.9% and an unweighted mean of 3.6% of their spending to education, with their spending distribution being the widest among all income groups. The high level of spending in lower-middle-income countries suggests that households face pressures to supplement inadequate public funding. In upper-middle-income countries, the median share of household budgets spent on education drops to 1.5%, with an unweighted mean of 2.3%. Finally, households in high-income countries dedicate a median of 1.3% or an unweighted mean of 1.7% to education.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,BGD,FRA" mdxType="ScrollySection">
          <p>{`For example, in 2019, `}<strong parentName="p">{`Bangladesh`}</strong>{` had one of the lowest levels of `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`government expenditure`}</HighlightByIndex>{` as a percentage of GDP. Yet, once `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`household contributions`}</HighlightByIndex>{` are taken into account, Bangladesh’s total national expenditure on education as a percentage of GDP was similar to that of `}<strong parentName="p">{`France`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,IND,BEL" mdxType="ScrollySection">
          <p>{`The government of `}<strong parentName="p">{`India`}</strong>{` spent about 2.4 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`percentage points of GDP`}</HighlightByIndex>{` less on education than did `}<strong parentName="p">{`Belgium`}</strong>{` – the blue bar –, but India spent overall more than Belgium because its `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`households`}</HighlightByIndex>{` spent more than 2.6 percentage points of GDP on education – the red bar.`}</p>
        </ScrollySection>
        <ScrollySection id="relative,NGA,LBN,HTI" mdxType="ScrollySection">
          <p>{`The `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`share of households`}</HighlightByIndex>{` in total education expenditure rises to over 70% in some countries, including `}<strong parentName="p">{`Nigeria`}</strong>{`, `}<strong parentName="p">{`Haiti`}</strong>{` and `}<strong parentName="p">{`Lebanon`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`The Global Education Monitoring Report team has long argued that understanding global education spending requires a joint examination of the three main sources of education funding: domestic public funding, external public funding and private funding. There is therefore a need for timely and consistent data from low- and middle-income countries on how much is being spent on education and from what sources.`}</p>
    <p>{`In addition to increasing the funds available for education, we also need to strengthen equitable distribution. In order to achieve the global education goal, SDG 4, it is important that countries and donors take responsibility and fulfil their commitments.  `}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2024efw"
          }}>{`Education Finance Watch 2024`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/financing-for-equity"
          }}>{`Unlocking Financing for Equity in Education`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://education-profiles.org/"
          }}>{`Profiles Enhancing Education Reviews (PEER)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/3141"
          }}>{`COVID-19 is a Serious Threat to Aid to Education Recovery`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;