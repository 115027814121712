
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Financiación";
export const lead = "Uno de cada tres países no cumple ninguno de los principales objetivos de referencia sobre financiación para los gobiernos esbozados en el Marco de Acción de Educación 2030.";
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Figure = makeShortcode("Figure");
const IndicatorReference = makeShortcode("IndicatorReference");
const ScrollyArea = makeShortcode("ScrollyArea");
const ScrollySticky = makeShortcode("ScrollySticky");
const ScrollySection = makeShortcode("ScrollySection");
const HighlightByPalette = makeShortcode("HighlightByPalette");
const HighlightByIndex = makeShortcode("HighlightByIndex");
const RelatedMaterial = makeShortcode("RelatedMaterial");
const layoutProps = {
  title,
lead
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Figure id="HERO_FINANCE" mdxType="Figure" />
    <blockquote>
      <p parentName="blockquote">{`Las ambiciosas metas que marcan los ODS en materia de educación no se alcanzarán a menos que se inviertan recursos adicionales, especialmente en los países más rezagados.`}</p>
    </blockquote>
    <p>{`El informe GEM calcula que, para conseguir de aquí a 2030 la meta de la educación básica universal (es decir, educación preescolar, primaria y secundaria) los países de ingreso bajo y mediano bajo deben invertir 461 mil millones de dólares estadounidenses.Es poco probable que muchos países aumenten adecuadamente sus presupuestos debido a los bajos ingresos fiscales, lo que se traduce en un déficit de financiación anual estimado en 97.000 millones de USD (el 21% del coste total) para alcanzar los objetivos nacionales entre 2023 y 2030. El déficit medio es de 26 000 millones USD (50% del total) en los países de renta baja y de 71 000 millones USD (17%) en los países de renta media-baja, lo que equivale al 2,3% del PIB durante este periodo.`}</p>
    <IndicatorReference id="4.5.3" mdxType="IndicatorReference">
      <p>{`Pero un incremento de los recursos no tiene por qué ser suficiente, pues países con niveles de gasto similares en términos de renta per cápita pueden obtener resultados educativos diametralmente opuestos dependiendo de la eficacia, la eficiencia y la equidad del gasto. Por ejemplo, de los 78 países de ingreso mediano y bajo sobre los que se dispone de información, solo 17 (es decir, 1 de cada 5) prestaron una atención significativa a la equidad en sus políticas de financiación, en gran medida países de ingreso mediano alto y de América Latina.`}</p>
    </IndicatorReference>
    <p>{`Las tres principales fuentes de gasto en educación son los gobiernos, los hogares y los donantes. Esta página se centrará en los dos primeros. Se dedica una página aparte a `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/es/articles/finance-aid"
      }}>{`la ayuda a la educación`}</a>{`.`}</p>
    <ScrollyArea mdxType="ScrollyArea">
      <ScrollySticky mdxType="ScrollySticky">
        <Figure id="TOTBYSOURCE" mdxType="Figure" />
      </ScrollySticky>
      <ScrollySection id="absolute,0" mdxType="ScrollySection">
        <p>{`En 2022, de los 5.8 billones de dólares estadounidenses de gasto en educación en todo el mundo, solo el 0,45% pertenece al gasto de los países de ingreso bajo, mientras que el 64% pertenece al de los países de ingreso alto. Los dos grupos, sin embargo, presentan un número aproximadamente similar de niños en edad escolar.`}</p>
      </ScrollySection>
      <ScrollySection id="relative,1" mdxType="ScrollySection">
        <p>{`De media en todo el mundo, `}<HighlightByPalette palette="06" index="1" mdxType="HighlightByPalette">{`los hogares`}</HighlightByPalette>{` sufragan el 25% del costo de la educación. De hecho, la factura educativa que recae sobre los hogares es más elevada en los países de ingreso mediano bajo (44%) que en los países de ingreso alto (20%).`}</p>
      </ScrollySection>
      <ScrollySection id="relative,2" mdxType="ScrollySection">
        <p>{`Aunque `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`los donantes`}</HighlightByPalette>{` aportan el 12% del gasto total en educación en los países de ingreso bajo...`}</p>
      </ScrollySection>
      <ScrollySection id="absolute,3" mdxType="ScrollySection">
        <p>{`...las contribuciones de `}<HighlightByPalette palette="06" index="8" mdxType="HighlightByPalette">{`los donantes`}</HighlightByPalette>{` representan apenas 0.3% del gasto total en todo el mundo, lo que, en importes absolutos, apenas es perceptible.`}</p>
      </ScrollySection>
    </ScrollyArea>
    <h2 {...{
      "id": "gasto-público"
    }}>{`Gasto público`}</h2>
    <IndicatorReference id="F.1" mdxType="IndicatorReference">
      <p>{`El `}<a parentName="p" {...{
          "href": "https://unesdoc.unesco.org/ark:/48223/pf0000245656"
        }}>{`Marco de Acción Educación 2030 `}</a>{` ha establecido dos objetivos de referencia financieros clave para los gobiernos, que les exhortan a:`}</p>
      <ul>
        <li parentName="ul">{`destinar al menos de un 4% a un 6% del PIB a la educación, y/o`}</li>
        <li parentName="ul">{`destinar al menos de un 15% a un 20% del gasto público a la educación.`}</li>
      </ul>
      <p>{`En términos generales, los gobiernos no están muy lejos de dichos objetivos. La mediana del gasto público es del 4,0% del PIB y del 12,6% del gasto público total. Los gobiernos de los países más pobres con poca capacidad para generar recursos internos pero con grandes cohortes de niños tienden a gastar menos en educación como porcentaje del PIB pero más como porcentaje del gasto público total.`}</p>
    </IndicatorReference>
    <IndicatorReference id="F.2" mdxType="IndicatorReference">
      <p>{`En África Subsahariana, el gasto público en educación supone el 15,5% del gasto público total, pero solo el 3,5% del PIB. Por el contrario, en Europa y América del Norte se cumple el objetivo financiero de referencia relativo al porcentaje del PIB (4,8%), pero el porcentaje del gasto público destinado a educación es bajo (10,9%).`}</p>
      <p>{`En términos generales, uno de cada tres países con datos recientes no cumple ninguno de los dos objetivos de referencia. Inicie la animación de la evolución de los datos a lo largo de los años y vea cómo ha ido fluctuando el gasto.`}</p>
    </IndicatorReference>
    <Figure id="GOVVGDP" mdxType="Figure" />
    <p>{`La principal fuente de ingresos de los gobiernos son los impuestos, también en los países de ingreso bajo que reciben un alto porcentaje de sus ingresos a través de subvenciones (es decir, ayuda). En general, la media de ingresos tributarios expresada como porcentaje del PIB es del 14% en los países de ingreso bajo, el 18% en los países de ingreso mediano bajo, el 22% en los países de ingreso mediano alto, y el 33% en los países de ingreso alto. Incluso si, por ejemplo, se diera la misma prioridad a la educación en los presupuestos de todos los países, los países con más impuestos y otros recursos nacionales gastarían más en educación.`}</p>
    <p>{`Ahora bien, la gran diferencia del gasto en educación entre países que, por lo demás, presentan características similares indica que no existe ninguna relación predeterminada al respecto. Algunos países deciden gastar más en educación para conseguir sus objetivos, y debe llamarse la atención sobre los que no lo hacen.`}</p>
    <h2 {...{
      "id": "gasto-de-los-hogares"
    }}>{`Gasto de los hogares`}</h2>
    <p>{`Los hogares destinan una cuantía significativa a la educación de sus hijos; en los países más pobres dicha cuantía es mayor que en los países más ricos.`}</p>
    <p>{`El gasto puede adquirir la forma de tasas de matrícula o de otro tipo, pero también puede ser para bienes y servicios educativos, algunos de los cuales son obligatorios (como los libros de texto) y otros no (como las clases particulares).`}</p>
    <IndicatorReference id="4.5.4" mdxType="IndicatorReference">
      <p>{`Cuando se uniformizan dichos datos entre países, surgen pruebas inequívocas de la contribución de los hogares al gasto total en educación, lo que puede modificar la forma en la que se interpretan los esfuerzos de los países en materia educativa.`}</p>
      <p>{`En los países de ingreso bajo, los hogares dedican menos del 1% de su presupuesto a la educación, limitados por las dificultades económicas. En cambio, los hogares de los países de ingreso mediano bajo destinan a la educación una media del 2,9% y una media no ponderada del 3,6% de su gasto, siendo su distribución del gasto la más amplia de todos los grupos de renta. El elevado nivel de gasto en los países de ingreso mediano bajo sugiere que los hogares se enfrentan a presiones para complementar una financiación pública inadecuada. En los países de ingreso mediano alto, el porcentaje medio del presupuesto familiar dedicado a la educación desciende al 1,5%, con una media no ponderada del 2,3%. Por último, los hogares de los países de ingreso alto dedican a la educación una mediana del 1,3% o una media no ponderada del 1,7%.`}</p>
      <ScrollyArea mdxType="ScrollyArea">
        <ScrollySticky mdxType="ScrollySticky">
          <Figure id="GOVHH_ABS_REL" mdxType="Figure" />
        </ScrollySticky>
        <ScrollySection id="absolute,BDG,FRA" mdxType="ScrollySection">
          <p>{`Por ejemplo, en 2019 `}<strong parentName="p">{`Bangladés`}</strong>{` presentaba uno de los niveles más bajos de `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`gasto público`}</HighlightByIndex>{` en cuanto a porcentaje del PIB. Sin embargo, si se tenía en cuenta la `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`contribución de los hogares`}</HighlightByIndex>{`, el gasto nacional total de Bangladés en educación en cuanto a porcentaje del PIB era similar al de `}<strong parentName="p">{`Francia`}</strong>{`.`}</p>
        </ScrollySection>
        <ScrollySection id="absolute,IND,BEL" mdxType="ScrollySection">
          <p>{`El Gobierno de la `}<strong parentName="p">{`India`}</strong>{` destinó a la educación casi 2,5 `}<HighlightByIndex index="0" mdxType="HighlightByIndex">{`puntos porcentuales del PIB`}</HighlightByIndex>{` menos que `}<strong parentName="p">{`Bélgica`}</strong>{` (la barra azul), pero en términos generales el gasto de la India fue superior al de Bélgica porque sus `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`hogares`}</HighlightByIndex>{` destinaron más de 2.6 puntos porcentuales del PIB a la educación (la barra roja).`}</p>
        </ScrollySection>
        <ScrollySection id="relative,NGA,LBN,HTI" mdxType="ScrollySection">
          <p>{`El `}<HighlightByIndex index="1" mdxType="HighlightByIndex">{`porcentaje del gasto total en educación que aportan los hogares`}</HighlightByIndex>{` asciende a más del 70% en algunos países, incluidos `}<strong parentName="p">{`Nigeria`}</strong>{`, `}<strong parentName="p">{`Haití`}</strong>{` y `}<strong parentName="p">{`Líbano`}</strong>{`.`}</p>
        </ScrollySection>
      </ScrollyArea>
    </IndicatorReference>
    <h2 {...{
      "id": "conclusión"
    }}>{`Conclusión`}</h2>
    <p>{`El equipo del Informe de seguimiento de la educación en el mundo lleva tiempo defendiendo que, para comprender el gasto mundial en educación, se deben examinar conjuntamente las tres fuentes principales de financiación educativa: la financiación pública y privada nacional - discutidas en esta página - y donantes externos, discutidos en la página de `}<a parentName="p" {...{
        "href": "https://www.education-progress.org/es/articles/finance-aid"
      }}>{`ayuda a la educación`}</a>{`. Por tanto, se necesitan datos oportunos y coherentes de los países de ingreso bajo y mediano sobre cuánto se está invirtiendo en educación y de qué fuentes procede dicha financiación.`}</p>
    <RelatedMaterial mdxType="RelatedMaterial">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.unesco.org/gem-report/en/2024efw"
          }}>{`Education Finance Watch 2024`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/financing-for-equity"
          }}>{`Unlocking Financing for Equity in Education`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://education-profiles.org/"
          }}>{`Profiles Enhancing Education Reviews (PEER)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.unesco.org/gem-report/node/3141"
          }}>{`COVID-19 is a Serious Threat to Aid to Education Recovery`}</a></li>
      </ul>
    </RelatedMaterial>


    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;